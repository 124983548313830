@import "Main.scss";

.restaurant {
  width: 100%;
  height: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
  padding-top: 2rem;

  .restaurant_heading {
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    color: $color-primary;
    font-family: Raleway, sans-serif;
    flex-direction: column;

    .restaurant_heading_text {
      height: 10%;
      font-size: 5rem;
      text-align: center;
      color: $color-primary;
    }

    .restaurant_heading_description {
      width: 80%;
      color: $color-secondary;
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: 2rem;
      line-height: 2.4rem;
    }

    .restaurant_menu {
      width: 100%;

      .menu_list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        flex-direction: row;
        gap: 10rem;

        .menu_list_element {
          color: $color-secondary;
          list-style: none;


        }

        .color_menu {
          color: $color-primary;
        }
      }
    }

  }


  .restaurant_content {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .restaurant_content_box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .restaurant_content_box_img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;


        img {
          width: 30%;
          height: 100%;
          object-fit: cover;
          box-shadow: 0 0 5rem .1rem rgba(0, 0, 0, .5);
          border-radius: 1rem;
          position: relative;


          &:hover {
            transform: scale(1.05);
            transition: all .5s ease-in-out;
          }


        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .restaurant {
    width: 100%;
    height: 90rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    padding-top: 2rem;

    .restaurant_heading {
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      color: $color-primary;
      font-family: Raleway, sans-serif;
      flex-direction: column;

      .restaurant_heading_text {
        height: 10%;
        font-size: 5rem;
        text-align: center;
        color: $color-primary;
      }

      .restaurant_heading_description {
        width: 80%;
        color: $color-secondary;
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 2rem;
        line-height: 2.4rem;
      }

      .restaurant_menu {
        width: 100%;

        .menu_list {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4rem;
          flex-direction: row;

          .menu_list_element {
            color: $color-secondary;
            list-style: none;


          }

          .color_menu {
            color: $color-primary;
          }
        }
      }

    }


    .restaurant_content {
      width: 100%;
      height: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .restaurant_content_box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .restaurant_content_box_img {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;


          img {
            width: 30%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0 0 5rem .1rem rgba(0, 0, 0, .5);
            border-radius: 1rem;
            position: relative;


            &:hover {

            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .restaurant {
    width: 100%;
    height: 80rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    margin-top: -5rem;
    margin-bottom: 0;
    .restaurant_content {
      width: 100%;
      height: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .restaurant_content_box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .restaurant_content_box_img {
          width: 100%;
          max-height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 10rem;


          img {
            width: 30%;
            height: 60%;
            object-fit: cover;
            box-shadow: 0 0 3rem .1rem rgba(0, 0, 0, .5);
            border-radius: 1rem;
            position: relative;


            &:hover {
              transform: scale(1.05);
              transition: all .5s ease-in-out;
            }
          }}}}
    .restaurant_heading {
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      color: $color-primary;
      font-family: Raleway, sans-serif;
      flex-direction: column;

      .restaurant_heading_text {
        height: 10%;
        font-size: 6rem;
        text-align: center;
        color: $color-primary;
      }

      .restaurant_heading_description {
        width: 100%;
        color: $color-secondary;
        font-size: 2rem;
        font-weight: 400;
        margin-top: 2rem;
        line-height: 2.4rem;
      }

      .restaurant_menu {
          width: 100%;
        margin: 0 auto;
        .menu_list {
          width: 95%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4rem;
          margin-bottom: 4rem;
          flex-direction: row;
          gap: 2rem;
          padding: 0;


          .menu_list_element {
            color: $color-secondary;
            list-style: none;
            font-size: 1.6rem;
          }
          .color_menu {
            color: $color-primary;
          } }

      }
    }
  }
}

