@import "Main.scss";

.contact {
  width: 100%;
  height: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .contact_container {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5rem;

    .contact_container_contact {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .contact_container_contact_box {
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        .contact_container_contact_phone, .contact_container_contact_email, .contact_container_contact_address {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          max-width: 100%;
          gap: 2rem;

          &:hover {
            scale: 1.1;

            i {
              color: $color-secondary
            }

            a, p {
              color: $color-primary;
            }
          }

          i {
            font-size: 3rem;
            color: white;
            padding: 1rem;
            background-color: $color-primary;
            border-radius: 50%;

          }

          a, p {
            font-size: 2rem;
            color: $color-secondary;
            text-decoration: none;
            font-weight: 600;
          }

        }

        .contact_container_contact_phone {
          .phone_restaurant {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

          }

        }

        .contact_container_contact_email {


        }

        .contact_container_contact_address {
          i {
            padding: 1rem 1.4rem;
          }

        }

      }

      .contact_container_contact_heading {
        width: 100%;
        font-size: 4rem;
        font-weight: 700;
        text-align: left;
        color: $color-secondary;
      }

      .contact_container_contact_text {
        width: 100%;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
        color: $color-secondary;
        margin-bottom: 2rem;
      }

    }

    .contact_container_map {
      width: 60%;
      height: 85%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      iframe {
        width: 100%;
        height: 100%;
        border: .2rem solid $color-primary;

      }
    }
  }

  .footer {
    width: 99vw;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #854000;
    color: whitesmoke;


    .footer_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      height: 100%;

    }

    .footer_container_left {

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      flex-direction: column;

      p {
        color: #1C1C1C;
        font-size: 1.2rem;
        font-weight: bolder;
      }

      .footer_container_left_logo {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 2.4rem;
          font-weight: 600;
          color: #1C1C1C;
          font-style: italic;
          font-family: 'Jost', sans-serif;
        }

        img {
          width: 8rem;
          height: 8rem;
          object-fit: cover;
        }
      }
    }

    .footer_container_middle {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .footer_container_middle_text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: .1rem;
        flex-direction: column;
        font-size: 1.4rem;
        font-weight: 500;
        color: #1C1C1C;
        font-family: 'Jost', sans-serif;
        text-align: left;
        margin-bottom: 1rem;

        a {
          text-decoration: none;
          color: #1C1C1C;

          &:hover {
            color: $color-secondary;
          }
        }

        p {
          font-weight: bolder;
        }

        .footer_container_middle_text_link {
          text-decoration: none;
          color: #1C1C1C;

        }
      }
    }

    .footer_container_right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      flex-direction: column;

      .footer_container_right_social {
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 2rem;
        flex-direction: row;
        text-decoration: none;
        color: white;

        .footer_container_right_social_facebook {

          a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 2rem;
            color: #1C1C1C;
            font-weight: 800;

          }

          i {
            color: #3b5998;

            &:hover {
              color: $color-secondary;
              scale: 1.1;
            }
          }
        }


        .footer_container_right_social_google_maps {

          a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 2rem;
            color: #1C1C1C;
            font-weight: 800;
          }

          i {
            color: tomato;

            &:hover {
              color: $color-secondary;
              scale: 1.1;
            }
          }


        }

        i {
          font-size: 3rem;
          padding: 2rem;
          background-color: white;
          border-radius: 20%;
        }


      }


    }
  }

}

@media only screen and (max-width: 500px) {
  .contact {
    height: 100rem;
    margin-top: -8rem;

    .contact_container {
      flex-direction: column;
      height: 100%;
      display: flex;
      justify-content: center;
      margin-top: -5rem;

      .contact_container_contact {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;


        .contact_container_contact_heading {
          margin: 0;
        }

        .contact_container_contact_text {
          font-size: 2rem;
          margin-bottom: 6rem;
          text-align: center;
        }
        .contact_container_contact_box{
          gap: 1rem;
          .phone_restaurant{
            flex-direction: column;
            align-items: center;
            p{
              margin-left: -3rem;
              width: 80%;
            }
          }
        }

      }

      .contact_container_map {
        width: 100%;
        height: 40%;

        iframe {
          width: 100rem;
          height: 80%;
          border: .2rem solid $color-primary;
          margin-top: 8rem;
        }

        .contact_container_contact_box {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: row;
          flex-wrap: wrap;

          .contact_container_contact_phone, .contact_container_contact_email, .contact_container_contact_address {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            gap: 2rem;

            &:hover {
              scale: 1.1;

              i {
                color: $color-secondary
              }

              a, p {
                color: $color-primary;
              }
            }

            i {
              font-size: 3rem;
              color: white;
              padding: 1rem;
              background-color: $color-primary;
              border-radius: 50%;

            }

            a, p {
              font-size: 2rem;
              color: $color-secondary;
              text-decoration: none;
              font-weight: 600;
            }

          }
        }
      }
    }

    .footer {
      width: 95vw;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-primary;
      color: whitesmoke;
      margin: 0;
      padding-bottom: 2rem;


      .footer_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;

      }

      .footer_container_left {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: -1rem;
        gap: 0;

        .footer_container_left_logo {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h4 {
            font-size: 1.8rem;
            font-weight: 600;
            color: whitesmoke;
            font-style: italic;
            font-family: 'Jost', sans-serif;
          }

          img {
            width: 6rem;
            height: 6rem;
            object-fit: cover;
          }
        }
      }

      .footer_container_middle {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 30%;

        .footer_container_middle_text {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: .1rem;
          flex-direction: column;
          font-size: 1.3rem;
          font-weight: 500;
          color: whitesmoke;
          font-family: 'Jost', sans-serif;
          text-align: left;
          margin-bottom: 1rem;

          a {
            text-decoration: none;
            color: white;

            &:hover {
              color: $color-secondary;
            }
          }

          p {
            font-weight: bolder
          }

          .footer_container_middle_text_link {
            text-decoration: none;
            color: white;

          }
        }
      }

      .footer_container_right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .footer_container_right_social {
          display: flex;
          justify-content: center;
          align-content: center;
          gap: 1rem;
          flex-direction: column;
          text-decoration: none;
          color: white;


          .footer_container_right_social_facebook {


            i {
              color: #3b5998;

              &:hover {
                color: $color-secondary;
                scale: 1.1;
              }
            }
          }



          .footer_container_right_social_google_maps {

            i {
              color: #4285f4;

              &:hover {
                color: $color-secondary;
                scale: 1.1;
              }
            }


          }

          i {
            font-size: 3rem;
            padding: 2rem;
            background-color: white;
            border-radius: 20%;
          }


        }


      }
    }
  }
}




