@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

$color-primary: #944200;
$color-secondary: #1C1C1C;
html {
  font-size: 10px;
  scroll-behavior: smooth;


  @media (max-width: 1024px) {
    font-size: 9px;
  }

  @media (max-width: 768px) {
    font-size: 9px;
  }

  @media (max-width: 425px) {
    font-size: 8px;
    
  }


}

body {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
  font-family: 'Raleway', sans-serif;

}

.container {
  display: flex;
  width: 85%;
  height: 100%;
  background-color: #f5f5f5;
  margin: 0 auto;
  flex-direction: column;
  box-sizing: border-box;

  .scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 12rem;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    margin: 0;
    transition: ease-in-out 2s;

  }


}

