@import "Main.scss";

.header {
  top: 0;
  width: 100%;
  height: 100rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;


  .header_content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;

    .header_content_top {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;

    }

    .content_text {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;

      .content_text_welcome {
        font-family: 'Dancing Script', cursive;
        font-size: 5rem;
        color: $color-primary;


      }

      .content_text_heading {
        margin-top: -1rem;

        font-size: 4.8rem;
        color: $color-secondary;
        font-weight: 700;
        text-align: left;
      }

      .content_text_description {
        font-size: 1.8rem;
        color: $color-secondary;
        font-weight: 300;
        text-align: left;
        font-family: Raleway, sans-serif;
        width: 80%;
        margin-top: -2rem;
        line-height: 2.5rem;
      }

      .content_link {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10rem;

        .content_link_button_first, .content_link_button_second {
          width: 20%;
          height: 6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-secondary;
          color: white;
          text-decoration: none;
          font-size: 1.6rem;
          font-weight: 500;
          border-radius: 1rem;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
            transition: all .5s ease-in-out;
          }
        }

        .content_link_button_second {

          background-color: $color-primary;


          &:hover {
            transform: scale(1.05);
            transition: all .5s ease-in-out;
          }
        }
      }
    }

    .content_img {
      width: 50%;
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-image: url(../Assets/hedaer_img.webp);
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      border-radius: 2rem;


      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2rem;

      }

      .header_info {
        position: absolute;
        bottom: 0;
        height: 8rem;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
        color: black;
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        align-content: center;
        justify-content: space-around;
        flex-direction: row;
        border-radius: 0 0 2rem 2rem;

        .header_info_up {
          display: flex;
          margin-top: 1rem;
        }

        i {
          color: white;
          padding: 1rem;
          border-radius: 1rem;
        }

        .header_info_phone {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          gap: 1rem;
          font-family: 'Jost', sans-serif;


          i {
            background-color: darkgreen;
          }


          p {
            text-decoration: none;
            color: black;

          }

          a {
            text-decoration: none;
            color: black;

            &:hover {
              transform: scale(1.05);
              transition: all .5s ease-in-out;
            }
          }
        }

        .header_info_location {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          gap: 1rem;
          font-family: 'Jost', sans-serif;
          margin-left: 2rem;

          i {
            background-color: tomato;
            padding: 1rem 1.2rem;


          }


        }

        .header_info_mail {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          gap: 1rem;
          font-family: 'Jost', sans-serif;
          margin-left: 2rem;


          i {
            background-color: dodgerblue;
          }

          a {
            text-decoration: none;
            color: black;

            p {
              text-decoration: none;
              color: black;

              &:hover {
                transform: scale(1.05);
                transition: all .5s ease-in-out;
              }
            }

          }


        }

      }
    }

  }

}

@media screen and (max-width: 1024px) {
  .header {
    .header_content {
      margin: 0;


      .content_link {
        margin: 0 auto;

        .content_link_button_first, .content_link_button_second {
          width: 30%;
          height: 5rem;
          font-size: 1.4rem;
          margin: 0 auto;

        }
      }

      .content_img {
        display: none;
      }

      .header_content_top {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;

        .content_text {


          width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 2rem;

          h2 {
            text-align: center;
            margin: 0;
            padding: 0;
          }

          h3 {
            text-align: center;
          }

          p {
            text-align: center;
          }


        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .header_content {
      margin-top: 5rem;

      .content_link {
        margin-top: -2rem;
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 0;
        a{
          padding: 1rem 2rem;
        }

        .content_link_button_first, .content_link_button_second,  {
          width: 20%;
          height: 6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-secondary;
          color: white;
          text-decoration: none;
          font-size: 1.6rem;
          font-weight: 500;
          border-radius: 1rem;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }


      .content_img {
        display: none;
      }

      .header_content_top {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;

        .content_text {


          width: 100vw;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 2rem;

          h2 {
            text-align: center;
            margin: 0;
            padding: 0;
          }

        h3{
            text-align: center;
          }

          .content_text_description {
            text-align: center;
            font-size: 2rem;
            width: 80vw;
          }


        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .header {

    .header_content {
      margin: 0;


      .content_link {
        margin-top: -2rem;

        .content_link_button_first, .content_link_button_second {
          width: 30%;
          height: 5rem;
          font-size: 1.4rem;
          margin: 0 auto;

        }
      }

      .content_img {
        display: none;
      }

      .header_content_top {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;

        .content_text {


          width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 2rem;

          h2 {
            text-align: center;
            margin: 0;
            padding: 0;
          }

          h3 {
            text-align: center;
          }

          p {
            text-align: center;
          }


        }
      }
    }
  }
}



