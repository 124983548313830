@import "Main";
.menu_restaurant{
  width: 100%;
  height: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


    .menu_restaurant_title{
      width: 100%;
      font-size: 4rem;
      font-weight: 700;
      text-align: left;
      color: $color-secondary;
      display: flex;
        justify-content: center;
      padding-top: 8rem;
      margin-bottom: 0;
    }

  .menu_restaurant_description{
    width: 90%;
    color: $color-secondary;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }

  .menu_restaurant_box{
    width: 100%;
    height: 70rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    background-color: #f5f5f5;
    margin-bottom: 5rem;

   img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
   }
  }


}

@media screen and (max-width: 1024px) {
  .menu_restaurant{
    height: 100rem;
    .menu_restaurant_box{
      height: 80rem;
    }
  }

}

@media screen and (max-width: 768px) {
  .menu_restaurant{
    height: 90rem;
    .menu_restaurant_box{
      height: 100rem;
      display: flex;
      justify-content: center;
        flex-direction: column;
      gap: 2rem;
      margin-top: 0;
    }
  }

}

@media screen and (max-width: 550px) {
  .menu_restaurant{
    height: 130rem;
    margin-top: -14rem;
    .menu_restaurant_box{
      width: 100%;
      height: 130rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: -4rem;
    }
  }

}