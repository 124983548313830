@import "Main.scss";


.gallery {
  width: 100%;
  height: 100rem;
  display: flex;
  flex-direction: column;


  .gallery_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 4rem;
      font-weight: 700;
      text-align: center;
      margin-top: 8rem;
    }
    h3{
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        margin-top: 2rem;
      color: #282c34;
    }

    .gallery_content_photos {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      flex-wrap: wrap;
      margin-top: 10rem;

      .gallery_content_photos_photo{
        width: 20%;
        height: 30rem;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.4);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        a{
            text-decoration: none;
            color: black;
        }

      h2{
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        margin-top: 2rem;

      }
      }

    }
  }

}

@media only screen and (max-width: 768px) {
    .gallery {
        width: 100%;
        height: 100rem;
        display: flex;
        flex-direction: column;
      .gallery_content_photos {
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column!important;
        justify-content: space-around;
        align-content: center;
        flex-wrap: nowrap!important;
        margin-top: 5rem!important;
        padding-bottom: 4rem!important;
        margin-left: 8rem;
        gap: 2rem;
        .gallery_content_photos_photo{
          width: 25rem!important;
          height: 25rem!important;
          border-radius: 1rem;
          overflow: hidden;
          box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.2);
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.4);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          a{
              text-decoration: none;
              color: black;
          }

}}}}
