.galeria {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  user-select: none;
  h3{
    font-size: 2rem;
    font-weight: 700;
    color: #282c34;

  }
}

.gallery-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: row;
}

.gallery-item {
  margin: 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 30rem;
  height: 30rem;
  object-fit: cover;
  cursor: pointer;
}

.zoom-icon {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;

  img {
    width: 10rem;
    height: 10rem;
    opacity: .8;
  }
}

.gallery-item:hover {
  transform: scale(1.1);
}

.gallery-item:hover .zoom-icon {
  opacity: 1;

  i {
    color: #fff;
    font-size: 5rem;
  }
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

}

.image-viewer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
    width: 100%;
    height: 100%;
}

.image-viewer img {
  max-width: 80%;
  max-height: 80%;

}

.close-button, .prev-button, .next-button {
  position: absolute;
  font-size: 4rem;
  color: #fff;
  cursor: pointer;
  padding: 2rem;
  background-color: #944200;
  border-radius: 1rem;
  transition: background-color 0.3s ease;
  width: 5rem;
  height: 5rem;
}

.close-button:hover, .prev-button:hover, .next-button:hover {
  background-color: #b55e00;
}

.close-button {
  top: 20%;
  right: 10rem;
}

.prev-button {
  top: 50%;
  left: 10rem;
  transform: translateY(-50%);
}

.next-button {
  top: 50%;
  right: 10rem;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .gallery-item img {
    width: 30rem;
    height: 30rem;
  }
  .image-viewer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100%;
    margin-right: 5rem;
  }

  .image-viewer img {
    max-width: 70%;
    max-height: 70%;

  }

  .close-button, .prev-button, .next-button {
    position: absolute;
    font-size: 4rem;
    color: #fff;
    cursor: pointer;
    padding: 1rem;
    background-color: #944200;
    border-radius: 1rem;
    transition: background-color 0.3s ease;
    width: 5rem;
    height: 5rem;
  }

  .close-button:hover, .prev-button:hover, .next-button:hover {
    background-color: #b55e00;
  }

  .close-button {
    top: 30%;
    right: 2rem;
  }

  .prev-button {
    top: 67%;
    left: 5rem;
    transform: translateY(-50%);
  }

  .next-button {
    top: 67%;
    right: 5rem;
    transform: translateY(-50%);
  }
}