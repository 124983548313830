@import "Main.scss";


.guesthouse {
  width: 100%;
  height: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5rem;

  .guesthouse_heading_text {
    height: 10%;
    font-size: 5rem;
    font-weight: 700;
    text-align: center;
    margin-top: 12rem;
    color: $color-secondary;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 30rem;
      height: .5rem;
      background-color: $color-primary;

    }

  }

  .guesthouse_rooms {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url("../Assets/background_guesthouse.webp");
    background-size: cover;
    flex-direction: column;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);

    }


    .guesthouse_rooms_box {
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 4;
    }

    .rooms_heading_text {
      font-size: 3.6rem;
      font-weight: 700;
      text-align: center;
      color: white;
      margin-top: 1.5rem;
    }

    .rooms_heading_description {
      width: 55%;
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      color: white;
      margin: 0;


    }

    .rooms_box {
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      z-index: 6;


      .room_box {
        width: 20%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        margin: 0 1rem;
        background-color: whitesmoke;
        border-radius: 2rem;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);

        &:hover {
          transform: scale(1.05);
          transition: all .5s ease-in-out;
        }

        .room_box_text {
          width: 100%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 1rem;

          .room_box_text_heading {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            color: $color-primary;
            margin: 0;
            font-family: Raleway, sans-serif;
          }

        }

        .room_box_img {
          width: 100%;
          height:75%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
            border-radius: 1rem;

          }
        }

      }
    }

  }

  .header_content_bottom {
    width: 100%;
    height:40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 5rem;
    margin-top: -6rem;

    .content_bottom_text {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 2rem;
      margin-top: 1rem;
      color: #282c34;

    }
  }

  .content_special_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    gap: 2rem;

    .content_special {
      width: 20%;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      border-radius: 1rem;

      .content_special_img {
        width: 100%;
        min-height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        &:hover {
          transform: scale(1.05);
          transition: all .5s ease-in-out;
        }

        i {
          margin: 0;
          font-size: 4rem;
          color: $color-primary;
        }

        .special {
          font-size: 2rem;
          margin: 0;
          color: #282c34;
        }
      }

    }


  }

}

@media screen and (max-width: 768px) {
  .guesthouse {
    height: 100rem;
    width: 90vw;
    margin-left: -2rem;
    .guesthouse_heading_text {
      font-size: 4rem;
      margin-top: 10rem;


      &:before {
        width: 20rem;
        height: .5rem;
      }
    }

    .guesthouse_rooms {
      height: 60%;

      .guesthouse_rooms_box {
        height: 35%;
      }

      .rooms_heading_text {
        font-size: 4rem;
        margin-bottom: 2rem;
      }

      .rooms_heading_description {

        width: 90%;
        font-size: 1.6rem;
        font-weight: 400;
      }

      .rooms_box {
        height: 50%;

        .room_box {
          width: 30%;
          height: 80%;

          .room_box_text {
            height: 30%;

            .room_box_text_heading {
              font-size: 1.6rem;
            }
          }

          .room_box_img {
            height: 70%;
          }
        }
      }
    }

    .header_content_bottom {
      height: 40%;
      margin-top: -4rem;

      .content_bottom_text {
        width: 95%;
        font-size: 2rem;
      }
    }

    .content_special_box {
      height: 30%;

      .content_special {
        width: 30%;
        height: 10rem;

        .content_special_img {
          width: 100%;
          min-height: 100%;

          i {
            font-size: 5rem;
          }

          .special {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}




