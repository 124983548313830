@import "Main";

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  transition: ease-in 2s;

  .header_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }

  .header_logo {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 80%;
      height: 80%;

      img {
        width: 100%;
        height: 100%;
        margin-top: 1rem;
      }
    }

    .logo_text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120%;
      height: 100%;

      .logo_text_heading {
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        font-style: italic;

      }

    }
  }

  .header_menu {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;


    /* Styl menu nawigacyjnego na wi?kszych ekranach */
    .menu {
      list-style: none;
      display: flex;
    }

    .menu li {
      margin: 0 20px;
    }

    /* Styl aktywnej ikony hamburgera */
    .menu-icon.open {
      /* Dodaj styl, kt�ry b?dzie aktywowany, gdy menu jest otwarte */
      /* Mo?esz dostosowa? ten styl do swoich preferencji */
    }



    a {
      max-width: 12%;
      text-decoration: none;
      color: #000;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 1.5rem 1rem;
      border-radius: 1rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: $color-primary;
        color: white;
      }

    }


  }
  .galeria_mobile {
    display: none;
  }

  .li_back_link {
    width: 80%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: 0;
    &:hover {
      color: white;
      transform: scale(1.1);
      transition: all .5s ease-in-out;

    }

    .back_link {
      display: flex;
      justify-content: center;
      max-width: 100%;
      text-decoration: none;
      color: white;
      font-size: 1.4rem;
      font-weight: 500;
      border-radius: 1rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

    }}
  .header_button_none {
    display: none;
  }

  .header_button_desktop {
    width: 80%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-bottom: 2rem;


    &:hover {
      transform: scale(1.1);
      transition: all .5s ease-in-out;
    }
  }



  .header_button {
    width: 10%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    margin: 2rem;
    cursor: pointer;


    &:hover {
      transform: scale(1.05);
      transition: all .5s ease-in-out;
    }


  }


}

@media screen and (max-width: 500px) {
  .nav {
    width: 100vw;
    margin-top:8rem;

    .header_nav {
      flex-direction: row;
      gap: 2rem;
      padding: 0;
      margin-left: 5rem;

      .mobile_menu {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
        margin-right: 4rem;

        .header_button_desktop {
          width: 60%;
          height: 3rem;
          margin: 0;
          padding: 1rem;
        }
        .li_back_link{
          margin: 0;
          padding: 1rem;
          width: 60%;
          height: 3rem;
          background-color: $color-primary;
          border-radius: 1rem;
        }

        .galeria_mobile {
          display: block;
          margin: 0;
          padding: 1rem;
          width: 80%;
          height: 3rem;
          background-color: $color-primary;
          border-radius: 1rem;
          list-style: none;

          .galeria_side {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 1.6rem;
            text-decoration: none;


            a {
              font-size: 1.6rem;
              font-weight: 500;


            }
          }
        }



      }

      .header_menu {
        display: none;
      }

      .menu-icon {
        display: block; /* Domy?lnie ukryte na wi?kszych ekranach */
        cursor: pointer;

        .menu_icon {
          width: 5rem;
          height: 3rem;
          margin: 4px 0;
        }
      }

      .bar {
        width: 25px;
        height: 3px;
        background-color: #333;
        margin: 4px 0;
      }

      /* Styl menu nawigacyjnego na mniejszych ekranach */
      .menu.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 10px;
        background-color: #fff;
        z-index: 1;
        padding: 10px;
        border: 1px solid #333;
      }


    }

    .header_button {
      display: block;
      width: 30%;
      height: 3rem;
    }


    .header_logo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 1rem;

      .logo {
        width: 50%;
        height: 100%;

        img {
          width: 90%;
          height: 80%;
        }
      }

      .logo_text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .logo_text_heading {
          font-size: 2.4rem;
          font-weight: 700;
          text-transform: uppercase;
          font-style: italic;

        }

      }
    }

  }
}