@import "Main.scss";


.party_rooms {
  width: 100%;
  height: 100rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  .party_contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .party_text {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      .party_text_heading {
        width: 100%;
        color: $color-primary;
        font-size: 2.4rem;
        font-weight: 500;
        font-family: 'Jost', sans-serif;
        text-align: left;
        position: relative;

        &:before {
          content: "";
          width: 15rem;
          height: .2rem;
          background-color: $color-primary;
          position: absolute;
          left: 35%;
          top: 55%;
          z-index: 100;


        }

        &:after {
          content: "";
          width: 2rem;
          height: 2rem;
          background-color: $color-primary;
          position: absolute;
          right: 40%;
          top: 50%;
          transform: translate(0, -50%);
          rotate: 45deg;
          z-index: 100;
        }
      }

      .party_text_title {
        width: 100%;
        color: $color-secondary;
        font-size: 3.6rem;
        font-weight: 600;
        text-align: left;
        margin-top: 2rem;
        text-transform: uppercase;
      }

      .party_text_description {
        width: 80%;
        color: $color-secondary;
        font-size: 1.6rem;
        font-weight: 400;
        text-align: left;
        margin-top: 2rem;
        line-height: 2.4rem;
      }

      .party_text_rooms {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5rem;
        margin-bottom: 10rem;
        gap: 15rem;
        color: $color-secondary;


        .party_text_room {
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          font-family: 'Jost', sans-serif;
          margin-left: 0;
          padding: 2rem 2rem;
          border: .1rem solid;
        }

        .color_room {
          color: $color-primary;
        }
      }
    }

    .party_img {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;


      .party_img_first {
        width: 60%;
        height: 50%;
        display: flex;
        position: absolute;
        right: 0;
        top: 15rem;
        box-shadow: 0 0 2rem .1rem rgba(0, 0, 0, 0.5);
        border-radius: 1rem;
      }

      .party_img_second {

        position: absolute;
        bottom: -2rem;
        width: 60%;
        height: 50%;
        box-shadow: 0 0 2rem .1rem rgba(0, 0, 0, 0.5);
        border-radius: 1rem;


      }
    }
  }

  .party {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 10rem;
    gap: 15rem;
    color: $color-secondary;

    .party_list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      font-size: 2rem;
      font-family: 'Jost', sans-serif;

      .party_list_element {
        max-width: 16%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        position: relative;
        font-weight: 500;
        font-family: 'Jost', sans-serif;
        text-transform: uppercase;


        &:hover {
          scale: 1.1;
          transition-duration: 1s;
        }


      }

      .color_list {
        color: $color-primary;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .party_rooms {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    .party {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15rem;
      color: $color-secondary;

      .party_list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'Jost', sans-serif;
        flex-wrap: wrap;
        gap: 2rem;

        .party_list_element {
          max-width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1.6rem;
          position: relative;
          font-weight: 500;
          font-family: 'Jost', sans-serif;
          text-transform: uppercase;


        }

        .color_list {
          color: $color-primary;
        }
      }
    }

    .party_contain {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .party_text {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        .party_text_heading {
          width: 100%;
          color: $color-primary;
          font-size: 2.4rem;
          font-weight: 500;
          font-family: 'Jost', sans-serif;
          text-align: left;
          position: relative;

          &:before {
            content: "";
            width: 15rem;
            height: .2rem;
            background-color: $color-primary;
            position: absolute;
            left: 35%;
            top: 55%;
            z-index: 100;

          }
        }
      }
    }

  }

}

@media only screen and (max-width: 768px) {
  .party_rooms {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    .party_contain {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .party_text {

        .party_text_heading {
          width: 100%;
          color: $color-primary;
          font-size: 2.4rem;
          font-weight: 500;
          text-align: left;
          position: relative;

          &:before {
            content: "";
            width: 15rem;
            height: .2rem;
            background-color: $color-primary;
            position: absolute;
            left: 60%;
            top: 55%;
            z-index: 100;


          }

          &:after {
            content: "";
            width: 2rem;
            height: 2rem;
            background-color: $color-primary;
            position: absolute;
            right: 40%;
            top: 50%;
            transform: translate(0, -50%);
            rotate: 45deg;
            z-index: 100;
          }
        }

        .party_text_description {
          width: 100%;
          font-size: 2rem;
          line-height: 3rem;
        }

        .party_text_rooms {
          width: 100%;
          height: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 5rem;
          margin-bottom: 10rem;
          gap: 5rem;
          color: $color-secondary;


          .party_text_room {
            width: 30%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            font-family: 'Jost', sans-serif;
            margin-left: 0;
            padding: 2rem 2rem;
            border: .1rem solid;
          }

          .color_room {
            color: $color-primary;
          }
        }


      }

      .party_img, img {
        display: none;
      }


    }
    .party {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -4rem;
      margin-bottom: 10rem;
      gap: 15rem;
      color: $color-secondary;
      margin-right: 5rem;

      .party_list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'Jost', sans-serif;
        flex-wrap: wrap;
        gap: .5rem;

        .party_list_element {
          max-width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1.6rem;
          position: relative;
          font-weight: 500;
          font-family: 'Jost', sans-serif;
          text-transform: uppercase;
        }
      }
    }
  }
}










